:root {
    --main-color: #8dc240;
    --lower-main-color: #8ec2407a;
    --secondary-color: #053162;
    --pro-green: #2b5719;
    --primary: #6a59ff;
    --white: hsl(0, 0%, 100%);
    --gray-text: #6f788b;;
    --border: #dde3ea;
  }
.dashboard .main-content{
    font-family: 'Poppins',sans-serif;
    display: flex;
    gap: 20px;
    height: fit-content;
}
.dashboard .main-content .dashboard-content{
    width: 100%;
    padding: 50px 15px;
}
.sidebar{
    position: relative;
    background-color: white;
    padding: 50px;
    width: 20%;
    box-shadow: 10px 0px 50px -60px #353333;
    height: 120vh;
}
.sidebar img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}
.sidebar ul{
    position: fixed;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.sidebar .sidebar-list .sidebar-list-item{
    display: flex;
    gap: 20px;
    font-weight: 600;
    font-size: 20px;
    color: var(--secondary-color);
    align-items: center;
    cursor: pointer;
    transition: all .3s ease;
}
.sidebar .sidebar-list .sidebar-list-item:hover{
    transform: translateX(10px);
}
.dashboard .navbar .second-nav{
    border: none;
}
.sidebar .sidebar-list .sidebar-list-item .notActive path{
    fill: var(--secondary-color);
}
/* Dashboard.css */
.sidebar .sidebar-list .sidebar-list-item.active{
    color: var(--main-color);
}
/* dashboard.css */
.active-icon path {
    fill: var(--main-color);
}
.dashboard .custom-select {
    position: relative;
    width: 30%;
    border-radius: 10px;
    color: var(--secondary-color);
    font-size: 17px;
    cursor: pointer;
}
.dashboard .custom-select input{
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    border: none;
    box-shadow: -10px 10px 80px -20px #35333373;
    font-size: 17px;
    border: 2px solid transparent;
    outline: none;
    font-family: 'Poppins',sans-serif;

}
.dashboard .custom-select input:focus{
    border: 2px solid var(--main-color);
}
.custom-select button {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: inherit;
    text-align: left;
}
.custom-input{
    display: flex;
    gap: 20px;
    align-items: center;
}
.custom-input .CancelIcon{
    width: 30px;
    cursor: pointer;
}
/*OFFRES*/
.offres .location-filter{
    font-size: 18px;
    color: var(--main-color);
    display: flex;
    font-weight: 500;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}
.offres .location-filter p {
    padding-left: 5px;
}
.list-offres{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
    margin-top: 50px;
    width: 100%;
}
.list-offres .offre-content{
    display: flex;
    gap: 10px;
    flex-direction: column;

}
.list-offres .offre{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    height: 100%;
    box-shadow: 0px 0px 50px -40px #353333;
    border-radius: 10px;
    padding: 20px;

}
.offre .offre-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offre .offre-title img{
    height: 25px;
}
.offre-title .payment-data{
    display: flex;
    align-items: center;
    gap: 10px;
}
.offre
.offre-title h1 {
    color: var(--secondary-color);
    font-size: 20px;
}
.list-offres .offre .top-offre{
    display: flex;
    gap: 20px;
    
}
.list-offres .offre .top-offre .top-left-offer img{
    width: 250px;
    border-radius: 10px;
    height: 166px;
    object-fit: contain;
}
.top-left-offer{
    width: fit-content;
    height: 166px;
    border-radius: 10px;
    background-color: var(--bg);
}
.list-offres .offre .top-offre .top-right-offer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    justify-content: space-around;
}
.top-right-offer .line{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: var(--secondary-color);
}
.top-right-offer .line .icon{
    width: 30px;
    height: 100%;
}
.bottom-offer p{
    color: var(--secondary-color);
    font-size: 17px;
    margin-top: 10px;
}
.bottom-offer p span{
    color: var(--main-color);
    font-weight: bold;
    font-size: 18px;
}

.order-date{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--secondary-color);
    font-weight: 700;
    margin: 30px 0;
    width: 100%;
}
.accepter .accept-btn{
    background-color: var(--main-color);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    font-weight: 500;
    color: white;
}
.accepter .accept-btn:hover{
    background-color: #6f9931;
}
.accepted{
    background-color: var(--secondary-color);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
}

.accept-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;

}
 .finish{
    background-color: var(--main-color);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    color: white;
}
.finish:hover{
    background-color: #6f9931;
}

.reject{
    background-color: rgb(226, 59, 59);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    color: white;
}
.reject:hover{
    background-color: rgb(170, 12, 12);
}

/*PROFILE*/
.profile {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-family: 'Poppins',sans-serif;
}

.profile .picture-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.picture-div {
    position: relative;
}

.picture-div .image-upload-placeholder {
    width: 200px;
    height: 200px;
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.picture-div .image-upload-input {
    display: none;
}

.picture-div .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.picture-div .image-upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.picture-div .image-upload-overlay:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.picture-div .edit-icon {
    opacity: 0;
    transition: all 0.3s ease;
    width: 30px;
    height: 100%;
}

.picture-div .image-upload-overlay:hover .edit-icon {
    opacity: 1;
}

.profile .picture-div h1 {
    color: var(--secondary-color);
    font-size: 30px;
}

.profile .photos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--secondary-color);
    gap: 20px;
}

.profile .photos .images {
    width: 100%;
    height: fit-content;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow-x: auto; /* Enable horizontal scrolling */
}
.photos .images .image{
    position: relative;
}
.photos .images .image .image-overlay {
    width: 150px;
    height: 150px;
    border-radius: 20px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.0);
    z-index: 998;
    position: absolute;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
.trash{
    width: 30px;
    height: 100%;
    opacity: 0;
    transition: all .3s ease;
}
.photos .images .image .image-overlay:hover{
    background: rgba(0, 0, 0, 0.3);
}
.photos .images .image .image-overlay:hover .trash{
    opacity: 1;
}
.photos .images .image img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 10px 10px 0px #3533331c;
    cursor: pointer;

}
.photos .images .image-uploader {
    border-radius: 20px;
    width: fit-content;
    height: fit-content;
}
.photos .images .image-uploader img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
    transition: all .3s ease;
}

.cleaner-description {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 100px;
}

.cleaner-description .bio {
    color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    font-family: 'Poppins',sans-serif;

}

.cleaner-description .bio .bio-content {
    width: 100%;
    padding: 20px;
    background: white;
    box-shadow: 0px 0px 50px 0px #35333313;
    border-radius: 10px;
    position: relative;
    min-height: 100px;
    height: auto;
}

.bio-content textarea {
    outline: none;
    border: none;
    font-size: 15px;
    width: 100%;
    min-height: 100px;
    font-family: 'Poppins',sans-serif;
}
.char-count {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: gray;
    font-size: 12px;
}
.char-count.max{
    color: red;
}
.save {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.save button {
    font-family: 'Poppins',sans-serif;
    width: fit-content;
    background-color: var(--main-color);
    padding: 10px 20px;
    color: white;
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    border: none;
    font-size: 15px;
    font-weight: 500;
}

.save button:hover {
    background-color: #739c34;
}

.cleaner-description .picker {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.picker h2,.picker h3 {
    color: var(--secondary-color);
    text-align: left;
}
.ajouter{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.picker .chosen{
    display: flex;
    flex-wrap: wrap; /* Wrap items to the next line when they exceed the container width */
    gap: 10px;
    align-items: center;
    width: 100%;
    border-radius: 0 20px 20px 20px;
    padding: 20px;
}
.chosen-service {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
    padding: 5px 25px;
    border-radius: 20px;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: all .3s ease;
    flex-basis: max-content; /* Set the initial size of each item based on its content */
  }

  .chosen-service svg{
    width: 20px;
    height: 100%;
  }
 .chosen-service:hover{
    background-color: #739c34;
    border: 2px solid #739c34;

  }
.picker .chosen .ajouter-btn {
    background-color: white;
    border: 2px dashed var(--main-color);
    width: fit-content;
    padding: 5px 25px;
    border-radius: 20px;
    color: var(--main-color);
    cursor: pointer;
}
.picker .chosen .ajouter-btn.adress {
    border: 2px dashed transparent;
    width: 50%;
    padding: 0;
    border-radius: 0px;
    color: var(--main-color);
    cursor: pointer;
}
.picker .chosen .ajouter-btn.adress .custom-select{
    width: 100%;
}
.service-list {
    position: absolute;
    display: flex;
    flex-wrap: wrap; /* Wrap items to the next line when they exceed the container width */
    gap: 10px;
    width: 35%;
    background-color: white;
    border-radius: 0 20px 20px 20px;
    box-shadow: 0px 0px 20px -5px #35333323;
    padding: 20px;
  }
  
  .service-list .service-item {
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
    padding: 5px 25px;
    border-radius: 20px;
    color: white;
    text-align: center;
    cursor: pointer;
    flex-basis: max-content; /* Set the initial size of each item based on its content */
  }

  .locations{
    width: 100%;
  }
  .locations .custom-select {
    position: relative;
    width: 50%;
    border-radius: 10px;
    color: var(--secondary-color);
    font-size: 17px;
    cursor: pointer;
}

.locations .custom-select button {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: inherit;
    text-align: left;
}

.locations .options-list {
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 2px solid var(--main-color);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1;
}

.locations .options-list li {
    padding: 10px;
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.locations .options-list li:hover {
    background-color: var(--main-color);
    color: white;
}
.spinner .loading-spinner-container{
    height: fit-content;
}
/*CREATE SERVICE PICKER*/
.service-creation{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.service-creation .create-service{
    color: var(--main-color);
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    border: 2px dashed var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 100px;
    gap: 20px;
    cursor: pointer;
    transition: all .3s ease;
}
.create-service:hover{
    background: #8ec24042;
}
.create-service svg path {
    fill: var(--main-color);
}

.created-service{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 50px -20px #35333349;

}
.created-service .img{
    display: flex;
    align-items: center;

}
.created-service img{
    border-radius: 10px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.created-service .right-created{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.right-created .left-right-created{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.right-created .left-right-created h1{
    color: var(--secondary-color);
    font-size: 20px;

}

.right-created .left-right-created p{
    color: var(--main-color);
    font-weight: 500;
    display: inline-flex;
    flex-wrap: wrap;
    width: 90%;
}
.left-right-created span{
    color: var(--secondary-color);
    font-weight: 400;
    margin-left: 10px;
}
.right-created .right-right-created{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
}
.right-right-created p {
    color: var(--main-color);
    font-size: 18px;
    font-weight: 500;
}

@media screen and (max-width: 768px) {

    .dashboard .main-content{
        width: 100% !important;
    }

    ::-webkit-scrollbar {
        height: 3px; /* Set the width of the scrollbar */
      }

    .sidebar{
        padding: 0;
        height: fit-content;
        width: 100% !important;
        position: static;
        z-index: 999;
        background-color: white !important;
    }
    .sidebar-list {
        position: static !important;

        display: flex;
        flex-direction: row !important;
        overflow-x: auto;
        padding: 10px;
        gap: 20px;
        justify-content: flex-start;
        width: 100%;
        background-color: white !important;

    }
    .sidebar-list-item{
        gap: 10px !important;
        min-width: 80px;
    }
    .sidebar-list .sidebar-list-item svg{
        width: 20px;
    }
    .sidebar-list-item p {
        font-size: 15px;
    }
    .location-filter .custom-select {
        width: 100% !important;
    }
    .profile .cleaner-description{
        grid-template-columns: repeat(1,1fr);
    }
    .picker .ajouter-btn{
        width: 100% !important;
    }
    .list-offres{
        grid-template-columns: repeat(1,1fr);
    }
    .list-offres .offre{
        width: 100%;
    }
    .offre .offre-content .top-offre{
        flex-direction: column;
    }
    .top-offre .top-left-offer{
        width: 100%;
    }
    .top-offre .top-left-offer img{
        width: 100% !important;
    }
    .accept-container{
        flex-direction: column;
    }
    .info-stat .row{
        grid-template-columns: repeat(1,1fr) !important;
    }

}

/*STATS*/

.info-stat .row{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 50px;
}


@media screen and (max-width: 1440px) {

    .sidebar{
        width: 30%;
    }
    .sidebar-list {
        padding: 0;
    }
   

}