@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Volkhov:wght@400;700&display=swap');

html {
  overflow-x: hidden;
}

:root {
  --main-color: #8dc240;
  --lower-main-color: #8ec2407a;
  --secondary-color: #053162;
  --pro-green: #2b5719;
  --primary: #6a59ff;
  --white: hsl(0, 0%, 100%);
  --gray-text: #6f788b;;
  --border: #dde3ea;
  
}
.split{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.vl{
  width: 3px;
  border-radius: 5px;
  background-color: var(--border);
  height: 500px;
}
.wilo{
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: var(--secondary-color);
  padding: 0 50px;
  width: 100%;
}
.form a{
  color: var(--main-color);
  text-decoration: underline;
}
.wilo .button{
  display: inline-flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  padding: 15px 35px;
  font-size: 15px;
  font-weight: bold;
  background: var(--main-color);
  border: 1px solid transparent;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: all .3s ease;
  box-shadow: 0px 10px 50px -0px transparent;
  margin: 100px 0;
  text-decoration: none;
}
.wilo .button:hover{
  background-color: white;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  box-shadow: 0px 10px 20px -5px var(--main-color);

}
.checkbox-container input{
  margin-right: 10px;
}
/* PAGE DESIGN */
.login {
  font-family: 'Poppins',sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login .logo {
  margin-top: 10px;
  width: 180px;
}

/* FORM */
.form {
  margin-top: 100px;
  width: fit-content;
  max-width: 60%;
  min-width: 25%;
  border-radius: 20px;
  box-shadow: 0 0 30px -10px rgba(128, 128, 128, 0.3);
  align-self: center;
  height: fit-content;
  color: var(--secondary-color);
}

.form-title {
  padding-top: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login .form-title h1 {
  font-size: 30px;
  color: var(--secondary-color);
}

/* FORM INPUTS */
.text-areas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding: 50px 100px;
  width: 100%;
  gap: 30px;
}

.input {
  height: 40px;
  width: 100%;
  position: relative;
}

.input input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
  color: var(--secondary-color);
  font-weight: 500;
}

.input input:focus ~ label,
.input input:not(:placeholder-shown) ~ label {
  transform: translateY(-20px);
  font-size: 15px;
  color: var(--main-color);
  margin-left: 0;
}

.input label {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all .3s ease;
  font-weight: 500;
  margin-left: 10px;
}
.text-areas .number{
  display: flex;
  width: 100%;
}

.input .underline {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100%;
  background: var(--border);
}

.input .underline:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--main-color);
  transform: scaleX(0);
  transition: all .3s ease;
}

.input input:focus ~ .underline:before,
.input input:not(:placeholder-shown) ~ .underline:before {
  transform: scaleX(1);
}

.text-areas button{
  padding: 15px 35px;
    font-size: 15px;
    font-weight: bold;
    background: var(--main-color);
    border: 1px solid transparent;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: all .3s ease;
    box-shadow: 0px 10px 50px -0px transparent;
    margin-top: 10px;
}

.text-areas button:hover{
  background-color: white;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  box-shadow: 0px 10px 20px -5px var(--main-color);

}

.text-areas p{
  color: #99999c;
  padding-top: 30px;
}
.text-areas .psw-forgot {
  padding: 0;
  margin-top: -10px;
  align-self: flex-end;
  cursor: pointer;
  text-decoration: underline;
}

.text-areas p span a {
  text-decoration: none;
}
.text-areas p span{
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.text-areas .other-methods{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.text-areas .other-methods svg{
  width: 35px;

}
.text-areas .other-methods button{
  color: var(--main-color);
    font-size: 15px;
    font-weight: bold;
    background: white;
    border: 3px solid var(--main-color);
    border-radius: 50px;
    cursor: pointer;
    transition: all .3s ease;
    box-shadow: 0px 10px 50px -0px transparent;
    margin: 0;
    padding: 10px 50px;
    display: flex;
    align-items: center;
}
.text-areas .other-methods button:hover{
  box-shadow: 0px 10px 20px -5px var(--main-color);

}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Optional: Ensure the spinner is not visible even when not hovered */
}

.candidature{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.candidature .form{
  width: 50%;
}
.candidature .form .text-areas{
  gap: 50px;
  align-items: flex-start;
}
.form-group{
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all .3s ease;
}
.form-group label{
  color: var(--gray-text);
  font-family: 'Poppins' , sans-serif;
  font-weight: 500;
}
.has-file{
  color: var(--main-color) !important;
}

@media screen and (max-width: 900px) {
  .candidature .form{
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .form{
    box-shadow: none;
    width: fit-content;
    max-width: 80%;
  }
  .text-areas{
    padding: 50px 0;
  }
  .form-title{
    padding-top: 0px;

  }
  .split{
    flex-direction: column;
  }
  .vl{
    display: none;
  }
  .wilo {
    padding: 0;
  }
  
}

