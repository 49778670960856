:root {
    --main-color: #8dc240;
    --lower-main-color: #8ec2407a;
    --secondary-color: #053162;
    --pro-green: #2b5719;
    --primary: #6a59ff;
    --white: hsl(0, 0%, 100%);
    --gray-text: #6f788b;;
    --border: #dde3ea;
  }

  .cart-page{
    font-family: 'Poppins',sans-serif;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .cart{
    width: 60%;
    display: flex;
    gap: 30px;
    margin-top: 50px;
  }
  .cart .bought-products,
  .cart .total{
    border-radius: 5px;
    width: 100%;
    box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.1);

  }
  .cart .title{
    color: var(--secondary-color);
    font-size: 20px;
    border-bottom: 1px solid var(--border);
    width: 100%;
    padding: 10px 20px;
  }
  .cart .title h3{
    font-weight: 500;
  }
  .cart-item{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .top-cart-item{
    display: flex;
    justify-content: space-between;
  }
  .cart-item .cart-left{
    display: flex;
    gap: 20px;
  }
  .cart-left h5{
    color: var(--secondary-color);
    font-size: 19px;
    font-weight: 400;
    padding: 5px 0 0 0;
  }
  .cart-left p{
    margin-top: -5px;
    color: var(--main-color);
    font-size: 15px;
  }
  .cart-item img{
    width: 75px;
    height: 75px;
    object-fit: cover;
    margin-left: 10px;
  }
  .cart-item-info{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
.cart .total{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: fit-content;
}
.cart-item .price{
    padding: 5px;
    font-size: 20px;
    color: var(--main-color);
    font-weight: 600;
}
.cart-item .bottom-cart-item .cart-trash {
    width: 20px;
    height: 100%;
}
.cart-item .bottom-cart-item{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.delete{
    display: flex;
    align-items: center;
    color: var(--main-color);
    gap: 10px;
    cursor: pointer;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
}
.delete p{
    font-weight: 500;
}

.delete:hover{
    background-color: #8ec2407a;
}
.Quantity{
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
}
.Quantity.info{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 200px;
}
.Quantity .number-btn{
    background-color: var(--main-color);
    padding: 7px 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: all .3s ease;
    cursor: pointer;
}
.Quantity .number-btn.disable{
    background-color:#8ec2407a ;
    cursor: not-allowed;
}
.number-btn:hover{
    background-color: #6f9931;
}
.Quantity .number-btn svg{
    height: 20px;
}
.Quantity .qt{
    color: var(--secondary-color);
    margin: 0 10px;
}
 .qt.alone p{
  color: var(--secondary-color);
  text-align: right;
  width: 100%;
  margin: 0;
  font-weight: 500;
  font-size: 17px;
}
.numbers{
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
}
.total-content{
    padding: 10px;
    display: flex ;
    justify-content: space-between;
    width: 100%;
}

.commander-btn{
    width: 100%;
    padding:10px;

}
.commander {
    color: white !important;
    width: 100%;
    font-weight: 600;
    text-align: center;
    background: var(--main-color);
    padding: 10px 25px;
    border-radius: 5px;
    border: 2px solid transparent;
    box-shadow: 0px 10px 20px -5px transparent;
    transition: all .3s ease;
    cursor: pointer;
    font-family: 'Poppins' ,sans-serif;
}

.commander:hover {
    background-color: white;
    border: 2px solid var(--main-color);
    color: var(--main-color) !important;
    box-shadow: 0px 10px 20px -5px var(--main-color);
}
.total-content p {
  font-weight: 400;
  font-size: 15px;
}
.total-content .final-price{
  color: var(--main-color);
  font-weight: 600;
}

/*BILLING*/
.billing-info{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}
.billing-info h1{
  color: var(--secondary-color);
}
.billing-info .input{
  width: 50%;
}
.billing-info .input.number{
  width: 120px;
}
.empty-cart-message{
  text-align: center;
  margin-top: 30px;
  color: var(--main-color);
}

.commander.disabled{
  cursor: not-allowed;
  background-color: #8ec2407a;
}
.commander.disabled:hover{
  cursor: not-allowed;
  color: white;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
.cart{
  flex-direction: column;
  width: 90%;
}
.total{
  width: 100% !important;
}
.cart-item .top-cart-item .cart-item-info h5{
  font-size: 15px;
}

.cart-item .numbers .price{
  font-size: 17px;
  display: inline-flex;
}
}