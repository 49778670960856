:root {
    --main-color: #8dc240;
    --lower-main-color: #8ec2407a;
    --secondary-color: #053162;
    --pro-green: #2b5719;
    --primary: #6a59ff;
    --white: hsl(0, 0%, 100%);
    --gray-text: #6f788b;;
    --border :#dde3ea;
  }

  .pro-profile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Poppins',sans-serif;

  }
.container-profile{
  display: flex;
  align-items: center;
  flex-direction: column;
}

  /* ProfileSlideshow.css */
  .slideshow {
    width: 90%;
    height: 500px; /* Set fixed height */
    overflow: hidden;
    position: relative;
    border-radius: 50px;
    box-shadow: 0 0 60px -10px rgba(128, 128, 128, 0.3);
  }
  
  .slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    transition: opacity 0.5s ease;
    opacity: 0;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .slide-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    position: relative;
  }
  
.info{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  
}
.banner{
  width: 110%;
  position: absolute;
  top: -380px;
  z-index: -10;
  left: auto;
}

.info img{
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 30px 0px #3533338e;

}
.info .profile-picture{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;

}
.info .white-circle{
  position: absolute;
  height: 250px;
  width: 250px;
  background-color: white;
  border-radius: 50%;
  z-index: -1;
  box-shadow: 0 0 60px -10px rgba(128, 128, 128, 0.3);
}

.info h1{
  color: white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.info h1 span{
  font-size: 15px;
  display: inline-flex;
  gap: 10px;
}

.data{
  width: 80%;
  display: flex;
  margin-top: 150px;
  gap: 50px;
  
}
.left-data{
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.left-data .data-line{
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 60px -10px rgba(128, 128, 128, 0.3);
  border-radius: 50px;
  padding: 40px 0;
}
.left-data .data-line .comment-section{
  width: 100%;
  height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.left-data .data-line .create-comment {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.create-comment .rating{
  display: flex;
  align-items: center;
justify-content: space-between;  padding-bottom: 20px;

}

.create-comment .rating .starIcon{
  cursor: pointer;
}
.stars{
  display: flex;
  align-items: center;
}
.stars p {
  font-size: 17px;
  font-weight: 500;
  margin-right: 10px;
}
.starIcon{
  width: 22px;
}
.starIcon path{
  fill: var(--border);
}
 .filled{
    fill: gold;
    width: 22px;
    cursor: pointer;
}
.create-comment .rating .starIcon:hover path{
  fill: gold;
}
.left-data .data-line .comment-section img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.left-data .data-line .create-comment textarea{
  font-family: 'Poppins',sans-serif;
  width: 100%;
  outline: none;
  border: none;
  resize: none;
  height: 100px;
  font-size: 15px;
  color: var(--secondary-color);
}

.left-data .data-line .comment{
  border-top: 1px solid var(--border);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-data .data-line .comment .person{
  display: flex;
  justify-content: space-between;
}
.person h3{
  color: var(--secondary-color);
  font-weight: 600;
}
.comment-content{
  justify-content: center;
  width: 80%;
}

.left-data .data-line .comment .person .left-person,.right-person{
  display: flex;
  gap: 20px;
  align-items: center;
}

.right-data{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.pro-profile  .title{
  color: var(--secondary-color);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin-top: 150px;
}
.pro-profile  .title span{
  background: var(--main-color);
  margin: 5px;
  padding: 5px;
  border-radius: 3px;
  transform: rotate(-5deg);
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.belowIcon{
  width: 50px;
  height: 100%;
  margin-top: 40px;
  animation: bounce 1s infinite; /* Apply the bounce animation */
}
.data-line{
  box-shadow: 0 0 60px -10px rgba(128, 128, 128, 0.3);
  border-radius: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.data-line.contact{
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.data-line button{
  background-color: var(--main-color);
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px transparent;
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: all .3s ease;
  font-weight: 600;
}
.data-line.contact p{
  font-size: 20px;
  padding: 8px 0;
}
.data-line button:hover{
  border: 2px solid var(--main-color);
  box-shadow: 0px 10px 20px 0px var(--main-color);
  color: var(--main-color);
  background-color: white;
}
.comment-section h1{
  margin: 20px 0;
}
.data-line h1{

  color: var(--secondary-color);
  font-size: 20px;
}
.data-line p{
  font-size: 16px;
  color: var(--secondary-color);
}
.pro-profile .chosen{
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next line when they exceed the container width */
  gap: 10px;
  align-items: center;
  width: 100%;
  border-radius: 0 20px 20px 20px;
}
.chosen .chosen-service {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  padding: 5px 25px;
  border-radius: 20px;
  color: white;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease;
  flex-basis: max-content; /* Set the initial size of each item based on its content */
}

.chosen-service svg{
  width: 20px;
  height: 100%;
}
.pro-profile .chosen .chosen-service:hover{
  background-color: #739c34;
  border: 2px solid #739c34;

}
.service-list {
  position: absolute;
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next line when they exceed the container width */
  gap: 10px;
  width: 35%;
  background-color: white;
  border-radius: 0 20px 20px 20px;
  box-shadow: 0px 0px 20px -5px #35333323;
}

.service-list .service-item {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  padding: 5px 25px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  flex-basis: max-content; /* Set the initial size of each item based on its content */
}
.service-item p{
  color: white;
}

.my-services{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.my-services .created-service{
transition: all .3s ease;
cursor: pointer;
}
.my-services .created-service:hover{
  transform: scale(1.05);
}

.comment-section .createdAt{
  display: flex;
  justify-content: right;
}
*.comment-section .createdAt p{
  color: var(--gray-text);
  font-size: 15px;
}

.card-right-side .rating{
  display: flex;
  gap: 10px;
  color: var(--secondary-color);
}
@media screen and (max-width: 1440px) {
  .info h1{
    color: var(--secondary-color);
  }
  .info h1 span{
    color: var(--main-color);
  }
}


@media screen and (max-width: 768px) {


  .info h1{
    color: var(--secondary-color);
  }
  .data{
    flex-direction: column;
  }
  .pro-profile .title{
    display: none;
  }
  .left-data{
    width: 100%;
  }
  .left-data .slideshow{
    height: 200px;
    border-radius: 30px;
  }
  .comment-section h1{
    display: none;  
  }
  .left-person{
    gap: 5px !important;
  }
  .left-person h3{
    font-size: 13px !important;
  }
  .left-person img{
    width: 30px !important;
    height: 30px !important;
  }
   .right-person .stars{
    font-size: 12px !important;
    width: 40px;
  }
  .right-person{
    gap: 5px;
  }
  .right-data{
    width: 100%;
  }
  .right-data .created-service{
    flex-direction: column;
    align-items: center;
  }
  .right-person .created-service .right-created ,.left-right-created{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}