:root {
    --main-color: #8dc240;
    --lower-main-color: #8ec2407a;
    --secondary-color: #053162;
    --pro-green: #2b5719;
    --primary: #6a59ff;
    --white: hsl(0, 0%, 100%);
    --gray-text: #6f788b;
    --border: #dde3ea;
}

.commande .title h1 {
    color: var(--secondary-color);
    font-size: 30px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    text-align: center;
    margin-top: 100px;
}

.commande .title h1 span {
    background: var(--main-color);
    margin: 5px;
    padding: 5px;
    border-radius: 3px;
    transform: rotate(-5deg);
}

.commande {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 100px;
    width: 100%;
    font-family: 'Poppins',sans-serif;
}

.commande-container {
    width: 60%;
    display: flex;
    align-self: center;
    gap: 100px;
}

.commande-container .left-commande,
.commande-container .right-commandes {
    width: 50%;
}

.commande-container .right-commandes {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

 .left-commande {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

 .left-commande .image-upload-placeholder {
    width: 100%;
    height: 300px;
    background-color: #f0f0f0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.left-commande .image-upload-input {
    display: none;
}
 .left-commande .image-upload-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
 .left-commande h2{
    color: var(--main-color);
    font-size: 20px;
    font-weight: 600;
}
 .left-commande .image-upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
    font-size: 18px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

 .left-commande .image-upload-overlay:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.commande .left-commande .description {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    background-color: white;
    box-shadow: 0px 0px 50px -20px #35333363;
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    gap: 10px;
}
.commande .left-commande .description.cree .creator img{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
}
.description.cree .creator{
    display: flex;
    width: 100%;
    justify-content: left;
    gap: 15px;
    align-items: center;
}
.description.cree .creator div{
    height: 40px;
}
.description.cree .creator .creator-name {
    font-weight: 500;
    /* Define gradient background using main color and secondary color variables */
    background: linear-gradient(0deg, var(--main-color) 10%, var(--secondary-color) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: all .3s ease;
    width: fit-content;
}


.commande .left-commande .description h1 {
    color: var(--secondary-color);
    font-size: 20px;
    border-bottom: 1px solid var(--border);
    width: 100%;
    text-align: left;
    padding: 5px;
}

.commande .left-commande .description label {
    color: var(--secondary-color);
    font-size: 15px;
    width: 100%;
    text-align: left;
    padding: 5px;
}

.commande .details-commande {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    background-color: white;
    box-shadow: 0px 0px 50px -20px #35333363;
    border-radius: 10px;
    padding: 10px 20px;
    gap: 10px;
}

.commande .right-commande .details {
    color: var(--secondary-color);
    font-size: 17px;
    border-bottom: 1px solid var(--border);
    width: 100%;
    text-align: left;
    padding: 5px 0 15px 0;
    font-weight: bold;
}

.commande .right-commande h2 {
    color: var(--secondary-color);
    font-size: 30px;
    width: 100%;
    text-align: left;
    font-weight: bold;
}
.availability p{
    color: var(--secondary-color);
}
.commande .right-commande .availability {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    color: var(--main-color);
    font-size: 17px;
}

.commande .right-commande .availability .Location {
    width: 20px;
}

.commande .right-commande .price {
    font-size: 15px;
    color: var(--gray-text);
    padding: 0 5px;
}

.commande .right-commande .price span {
    font-size: 20px;
    color: var(--main-color);
    font-weight: bold;
}

.commande-container .left-commande .description {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    background-color: white;
    box-shadow: 0px 0px 50px -20px #35333363;
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    gap: 10px;
}

.commande-container .left-commande .description h1 {
    color: var(--secondary-color);
    font-size: 20px;
    border-bottom: 1px solid var(--border);
    width: 100%;
    text-align: left;
    padding: 5px;
}

.commande-container .left-commande .description label {
    color: var(--secondary-color);
    font-size: 15px;
    width: 100%;
    text-align: left;
    padding: 5px;
}

.right-commande .price {
    font-size: 15px;
    color: var(--gray-text);
    padding: 0 5px;
}

.right-commande .price span {
    font-size: 20px;
    color: var(--main-color);
    font-weight: bold;
}

.right-commande .details-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--border);
    width: 100%;
    padding: 0 0 15px 0;
}

.right-commande .date {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border);
    height: fit-content;
}

.right-commande .date label {
    color: var(--secondary-color);
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
}

.right-commande .date input {
    width: 100%;
    border: 2px solid var(--main-color);
    border-radius: 10px;
    padding: 10px;
    font-size: 17px;
    color: var(--secondary-color);
    outline: none;
}

.right-commande .notes label {
    color: var(--secondary-color);
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
}

.notes {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    padding-bottom: 15px;
    font-family: 'Poppins',sans-serif;

}

.notes textarea {
    width: 100%;
    height: 100%;
    min-height: 100px;
    border: 2px solid var(--main-color);
    border-radius: 10px;
    resize: none;
    color: var(--secondary-color);
    font-size: 15px;
    padding: 10px;
    outline: none;
    font-family: 'Poppins',sans-serif;

}
.right-commande .reserver-btn {
    color: white;
    width: fit-content;
    font-weight: 600;
    background: var(--main-color);
    padding: 10px 25px;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: 0px 10px 20px -5px transparent;
    transition: all .3s ease;
    cursor: pointer;
    
}

.right-commande .reserver-btn:hover {
    background-color: white;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    box-shadow: 0px 10px 20px -5px var(--main-color);
}

.total-container {
    background-color: white;
    box-shadow: 0px 0px 50px -20px #35333363;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.total {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total label {
    color: var(--secondary-color);
    font-size: 17px;
    font-weight: 500;
}

.total span {
    color: var(--main-color);
    font-weight: 600;
    font-size: 20px;
}

.custom-select {
    font-family: 'Poppins',sans-serif;

    position: relative;
    width: 100%;
    border-radius: 10px;
    color: var(--secondary-color);
    font-size: 17px;
    cursor: pointer;
}

.custom-select button {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: inherit;
    text-align: left;
    font-family: 'Poppins',sans-serif;

}

.options-list {
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 2px solid var(--main-color);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1;
    font-family: 'Poppins',sans-serif;

}

.options-list li {
    padding: 10px;
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-family: 'Poppins',sans-serif;

}

.options-list li:hover {
    background-color: var(--main-color);
    color: white;
}

.quantity input {
    border: 2px solid var(--main-color);
    border-radius: 10px;
    text-align: center;
    padding: 10px 0;
    outline: none;
    width: 50px;
    font-size: 15px;
}

.quantity label {
    color: var(--secondary-color);
    font-size: 17px;
    font-weight: 500;
}

.quantity {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 20px;
}

.flatpickr-calendar {
    display: flex;
    flex-direction: row;
}

.flatpickr-time {
    margin-left: 10px;
}
@media screen and (max-width: 768px) {
.commande-container{
    flex-direction: column;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.commande-container div{
    width: 90% !important;    
}
.commande-container .left-commande .image-upload-placeholder{
    width: 100% !important;
}
.commande-container .left-commande{
    align-items: center;
}

.right-commandes{
    width: 100% !important;
    justify-content: center;
    align-items: center;
}
.availability div{
    flex-wrap: wrap !important;
}
.availability p{
    min-width: 100px !important;
}
}