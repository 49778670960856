:root {
    --main-color: #8dc240;
    --lower-main-color: #8ec2407a;
    --secondary-color: #053162;
    --pro-green: #2b5719;
    --primary: #6a59ff;
    --white: hsl(0, 0%, 100%);
    --gray-text: #6f788b;;
    --border :#dde3ea;
}

.payment{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px;
}



  .payment {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .payment .payment-content{
    display: flex;
    align-items: flex-start;
    gap: 50px;
    width: 60%;
  }
  .payment-content .sub{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .payment-content h2{
    color: var(--secondary-color);
    font-family: 'Poppins' , sans-serif;
  }

  .commande-payment{
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 50px -20px #35333363;
    width: 100%;
    padding: 20px;
  }
  .commande-payment p{
    color: var(--secondary-color);
    font-size: 20px;
    font-family: 'Poppins' , sans-serif;
  }
  .commande-payment p span{
    color: var(--main-color);
    font-weight: 500;
  }
  .pay-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .pay-container div{
    width: 100%;
  }
  .pay-container img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
  }
  .pay-container .order-details{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
    width: 100%;
  }
  .methods{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .methods .method{
    border: 1px solid var(--border);
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    box-shadow: 0px 0px 50px -15px #35333363;
    transition: all .3s ease;
  }
  .fill{
    border: 1px solid var(--border);
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    box-shadow: 0px 0px 50px -15px #35333363;
    transition: all .3s ease;
    background-color: var(--lower-main-color);
  }

  .method:hover{
    background: var(--border);
  }
  .methods img{
    height: 30px;
    
  }
  .methods p{
    color: #232323;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    .payment{
      margin: 20px;
    }
    .payment .payment-content{
    flex-direction: column;
    width: 100% !important;
   }
   .payment-content .sub{
    width: 100% !important;
    text-align: center;
   }
  }