:root {
  --main-color: #8dc240;
  --lower-main-color: #8ec2407a;
  --secondary-color: #053162;
  --pro-green: #2b5719;
  --primary: #6a59ff;
  --white: hsl(0, 0%, 100%);
  --gray-text: #6f788b;;
  --border: #dde3ea;
}
.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px; /* Adjust height as needed */
    width: 100%;
   
  }
  .loading-spinner-container.micro{
    height: 30px;
  }
  .loading-spinner {
    border: 5px solid white;
    border-left-color: var(--main-color); /* Change spinner color */
    border-radius: 50%;
    width: 50px; /* Adjust spinner size */
    height: 50px; /* Adjust spinner size */
    animation: spin 1s linear infinite;
  }
  .loading-spinner-container.micro .loading-spinner{
    border: 2px solid var(--main-color);
    border-left-color: white; /* Change spinner color */
    width: 20px; /* Adjust spinner size */
    height: 20px; 
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  