@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Volkhov:wght@400;700&display=swap');
* {
  scroll-behavior: smooth;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color); /* Set the color of the scrollbar thumb */
  border-radius: 3px; /* Set the border radius of the scrollbar thumb */
}
* {
  scroll-behavior: smooth;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}
:root {
  --main-color: #8dc240;
  --secondary-color: #053162;
  --pro-green: #669353;
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
  --border:#dde3ea;
}
a{
  text-decoration: none;
}
.page-title{
  color: var(--secondary-color);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Poppins',sans-serif;
  margin-top: 50px;
  width: 100%;
}
.page-title span{
  background: var(--main-color);
  margin: 5px;
  padding: 5px;
  border-radius: 3px;
  transform: rotate(-5deg);
}

.landing{
  display: flex;
  flex-direction: column;
}
/*TESTIMONIES*/
.testimonies{
  display: flex;
  justify-content: space-around;
  align-items: left;
  text-align: left;
}

.testimonies .text h1{
  font-size: 50px;
  font-family: 'Volhkov',serif;
  color: var(--secondary-color);
  margin-top: -30px;
}
.testimonies .text h6{
  font-size: 16px;
  padding-bottom: 30px;
  font-family: 'Poppins',sans-serif;
  text-transform: uppercase;
  color: #5E6282;
}
.testimonies .text{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 150px;
}


/*FOOTER*/
@media screen and (max-width: 768px) {
  .page-title{
    font-size: 20px;
  }
  .services .page-title,
  .boost .page-title{
    flex-direction: column;
  }
}