:root {
    --main-color: #8dc240;
    --secondary-color: #053162;
    --pro-green: #669353;
    --primary: #6a59ff;
    --white: #ffffff;
    --bg: #f5f5f5;
    --border:#dde3ea;
}

.navbar {
    width: 100%;
    background-color: white;
    font-family: 'Poppins',sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navbar .first-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dde3ea;
    border-radius: 10px;
    box-shadow: 10px -40px 50px #353333;
    width: 100%;
    padding-right: 20px;
}

.navbar .first-nav .disconnect {
    cursor: pointer;
    width: 15px;
}

.navbar .first-nav .disconnect:hover path,
.navbar .first-nav .history:hover path {
    fill: var(--main-color);
}


.navbar .first-nav .left-nav, .account {
    display: flex;
    align-items: center;
    margin: auto 20px;
}


.navbar .first-nav p {
    color: var(--secondary-color);
    font-size: 16px;
    transition: all .3s ease;
    cursor: pointer;
    padding: 15px 0px;
}

.navbar .first-nav p:hover {
    color: var(--main-color);
}

.navbar a {
    text-decoration: none;
}

.navbar .first-nav svg {
    width: 20px;
}

.navbar .first-nav svg path {
    transition: all .3s ease;
}

.Logo {
    width: 100px;
}

.PhoneIcon {
    cursor: pointer;
}

.PhoneIcon path {
    fill: var(--secondary-color);
}

.navbar .left-nav .PhoneIcon:hover path {
    fill: var(--main-color);
}

.ArrowIcon {
    cursor: pointer;
}

.ArrowIcon path {
    stroke: var(--secondary-color);
}

.navbar .left-nav .ArrowIcon:hover path {
    stroke: var(--main-color);
}

.PipeIcon path {
    fill: var(--secondary-color);
}

.PersonIcon path {
    fill: var(--secondary-color);
}

.PersonIcon:hover path {
    fill: var(--main-color);
}

/*SECOND NAV*/
.second-nav {
    display: flex;
    justify-content: space-around;
    height: 70px;
    border-bottom: 1px solid #dde3ea;
    width: 100%;
}

.second-nav .second-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.second-left svg.Logo {
    height: 60px;
    width: 70% !important;
}

.second-left p {
    font-size: 15px;
    font-weight: bold;
    color: var(--secondary-color);
    transition: all .3s ease;
}

.second-left svg {
    width: 20px;
}

.second-left p .PhoneIcon path {
    stroke: var(--secondary-color);
}

.second-left ul.dropdown li {
    display: block;
}

.second-left ul.dropdown {
    width: 100%;
    position: absolute;
    z-index: 999;
    display: none;
}

.second-left ul li a:hover {
    background: var(--main-color);
}

.second-left .trouver:hover ul.dropdown {
    display: block;
}

.second-left p:hover,
.second-left p:hover svg path {
    color: var(--main-color);
    cursor: pointer;
    stroke: var(--main-color);
}

.second-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.second-right .container {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.second-right p {
    font-size: 16px;
    font-weight: bold;
    color: var(--main-color);
    padding: 0;
    text-align: center;
}

.second-right h1 {
    font-size: 18px;
    color: var(--secondary-color);
}

.second-right svg {
    height: 100%;
    width: 35px;
}

.second-right .phoneIcon path {
    fill: var(--secondary-color);
}

/* Media Queries for responsiveness */
.second-nav .navbar-list{
    display: flex;
    gap: 30px;
}
.hamburger-menu path{
    fill: var(--main-color);
}
/* Add this CSS to style the sidebar */
.navbar-list .sidebar-top{
    display: none;
}
.hamburger-menu{
    display: none;
}
/* navbar.css */
.regular-navbar {
    width: 100%;
    background-color: transparent; /* Set the background color of the regular navbar */
    z-index: 1000;
    background-color: white;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .regular-navbar .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px; /* Adjust padding as needed */
  }
  
  .regular-navbar .navbar-content .logo {
    width: 120px; /* Adjust logo size as needed */
  }
  
  .regular-navbar .navbar-content .menu-items {
    display: flex;
    gap: 20px;
  }
  
  .regular-navbar .navbar-content .menu-items a {
    text-decoration: none;
    color: #fff; /* Set the color of menu item text */
    font-size: 18px; /* Set font size of menu item text */
    font-weight: bold;
  }
  
  .regular-navbar .navbar-content .menu-items a:hover {
    color: #ffcc00; /* Change color on hover */
  }
  /* navbar.css */
    .navbar.fixed-navbar {
    position: fixed;
    top: 0px; /* Initially position navbar above the viewport */
    left: 0;
    height: fit-content;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.466); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Add backdrop blur effect */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    z-index: 1000;
    transition: top 0.5s ease;
    }
    .navbar.fixed-navbar .second-nav{
    border: none;
    }
    

  
@media screen and (max-width: 768px) {
    .navbar .first-nav .left-nav, .account {
        display: flex;
        align-items: center;
        margin: auto 5px;
    }
    .navbar.fixed-navbar .navbar-list{
        height: 100vh;
        }
    .account{
        gap: 10px;
    }
  .navbar .first-nav p {
    font-size: 10px;
  }
  .navbar .first-nav svg {
    width: 10px;
  }
  .navbar .first-nav .account svg{
    width: 10px;
  }
  .second-nav{
    padding: 0 20px;
  }
  .second-nav .second-right .container h1{
    font-size: 15px;
  }
  .second-nav img{
    width: 100px;
    height: 100%;
  }
  .second-nav .navbar-list {
    position: absolute;
    background-color: white;
  }

  .navbar-list {
    position: fixed;
    top: 0;
    left: -100%;
    width: 70%; /* Adjust width as needed */
    height: 100%;
    background-color: #ffffff;
    z-index: 999;
    transition: left 0.3s ease;
    padding-top: 50px; /* Adjust as needed */
  }
  .navbar-list.show-sidebar {
    left: 0;
    background-color: white;
  }
  .navbar-list {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust gap as needed */
    position: absolute;
    top: 0px; /* Adjust top position as needed */
    left: -100%; /* Initially hide the sidebar */
    width: 300px; /* Set the width of the sidebar */
    background-color: #ffffff; /* Background color of the sidebar */
    transition: left 0.3s ease; /* Smooth transition for opening and closing */
  }
  .hamburger-menu{
    display: block;
  }
  .navbar-list.show {
    left: 0; /* Show the sidebar when the 'show' class is added */
  }
  .navbar-list .sidebar-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-list a p:hover{
    transform: translateX(10px);
  }
  .second-right .shopping-cart a svg{
    width: 30px;
  }
  .second-right svg{
    width: 20px;
}
.second-right .container{
    display: none;
}
.second-right .phoneIcon{
    display: none;
}
}
