:root {
    --main-color: #8dc240;
    --secondary-color: #053162;
    --pro-green: #669353;
    --primary: #6a59ff;
    --white: #ffffff;
    --bg: #f5f5f5;
    --border :#dde3ea;
  }
  .top{
    background-blend-mode: multiply; /* This applies the darkening effect */
    background-color: #0530626c; /* Adjust opacity for desired darkness */
    background-position: center;
    background-size: cover;
    height: 600px;
    font-family: 'Poppins',sans-serif;
    position: relative;
  }
  .top .background{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .top .container{
    height: 100%;
    padding: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
.top .container h1{
    color: white;
    font-size: 40px;
    font-weight: bold;
}
.top .container p{
    color: white;
    font-size: 25px;
}
.top .container .search{
    display: flex;
    width: fit-content;
    margin-top: 30px;
    height: 75px;    
    border-radius: 10px;
    position: relative;
    bottom: 0;
    background: white;
    display: flex;
    justify-content: space-around;
}
.search div{
  width: 100%;
  height: 100%;

}
.service-rech{
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  gap: 15px;
  border-right: 1px solid var(--border);
}
.SearchIcon{
  width:30px;
}
.top input{
  border: none;
  font-size: 20px;
  color: var(--secondary-color);
  outline: none;
  font-family: 'Poppins',sans-serif;
  height: 100%;
}
.top input::placeholder{
  color: #99999C;
}

/*LOCALISATION*/
.localisation{
display: flex;
}
.localisation div{

  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  gap: 15px;
}
.localisation button{
  height: 80%;
  padding: 15px 35px;
  margin-right: 8px;
  font-size: 15px;
  font-weight: bold;
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  box-shadow: 0px 10px 20px -5px transparent;
  border: 1px solid transparent;
  transition: all .3s ease;
  font-family: 'Poppins',sans-serif;

}
.localisation button:hover {
  background-color: white;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  box-shadow: 0px 10px 20px -5px var(--main-color);
}


@media screen and (max-width: 768px) {
  .top .background{
    width: 100%;
  }
  .top{
    height: fit-content;
  }
  .top .container{
    padding: 100px 0;
  }
  .top .container h1{
    font-size: 20px;
    font-weight: bold;
    width: 100%;
  }
  .top .container p{
    font-size: 15px;
  }
  .top .container .search{
    display: none;
    width: 100px;
  }

}