:root {
    --main-color: #8dc240;
    --lower-main-color: #8ec2407a;
    --secondary-color: #053162;
    --pro-green: #2b5719;
    --primary: #6a59ff;
    --white: hsl(0, 0%, 100%);
    --gray-text: #6f788b;;
    --border: #dde3ea;
  }

  input{
    font-family: 'Poppins',sans-serif;

  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.418);
    z-index: 9999;
        font-family: 'Poppins',sans-serif;


}
.popup-title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding: 0 40px;
}
.popup-title p{
    min-width: 30px;
}
.popup .popup-content{
    z-index: 10000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: fit-content;
    max-height: 90%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto !important;
    gap: 30px;
    padding: 40px;
}

.input-with-list{
    display: flex;
    gap: 30px;
    align-items: center;
}
.input-with-list svg{
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
}
.input-with-list select{
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 10px;
    outline: none;
    font-family: 'Poppins' , sans-serif;

}
.input-with-list select option{
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid silver;
    color: var(--secondary-color);
    font-weight: 500;
}
.popup .popup-content.log{
    width: fit-content;
    padding: 40px;
}
.popup .popup-content.otp{
    width: 30%;
    text-align: center;
    padding: 50px;
}



.popup-content p{
    color: var(--secondary-color);
}
.popup-content p span{
    color: var(--main-color);
    font-weight: 500;
}
.popup-content.log .popup-title{
   align-items: center;
}
.popup-content.log h1{
    padding: 0;
    margin: 0;
}
.popup-content h1{
    color: var(--main-color) !important;
}
.creation-content{
    display: flex;
    width: 100%;
    padding: 20px 100px;
    justify-content: flex-start;
    gap: 30px;
}

.left-creation{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    text-align: center;
}
.right-creation{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.right-creation .crea
.popup-content img.popup-img{
    width: 50%;
    height: 30%;
}

.popup .bio-content {
    padding: 20px;
    background: white;
    box-shadow: 0px 0px 50px 0px #35333313;
    border-radius: 10px;
    font-family: 'Poppins',sans-serif;
    position: relative;

}

.bio-content textarea {
    outline: none;
    border: none;
    font-size: 15px;
    width: 100%;
    height: 150px;
    resize: none;
    font-family: 'Poppins',sans-serif;

}

.picker.ofpopup{
    width: 80% !important;
    padding-bottom: 30px;

}
.picker-container{
    display:flex;
    justify-content: space-between;
    width: 100%;
}
.picker.ofpopup h2{
    font-size: 20px;
}

.picker.ofpopup .chosen{
    padding: 0;
}
.picker.ofpopup .service-list{
    width: 60%;
}

.popup .input.number{
    width: fit-content;
}
.popup .periode .input.number{
    width: 100% !important;
}
.periode{
   margin-top: 10px;
}
.periode .input-with-list p {
    color: var(--gray-text);
}
.create-btn p {
    color: white;
    width: fit-content;
    font-weight: 600;
    background: var(--main-color);
    padding: 10px 25px;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: 0px 10px 20px -5px transparent;
    transition: all .3s ease;
    cursor: pointer;

}
.create-btn {
    
    display: flex;
    justify-content: center;
    width: 100%;
}

.create-btn p:hover {
    background-color: white;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    box-shadow: 0px 10px 20px -5px var(--main-color);
}

.left-commande.ofpopup{
    width: 80%;
}
.left-commande.ofpopup h1{
    font-size: 13px;
}
.cancelIcon{
    cursor: pointer;
}

.success-message {
    color: var(--main-color);
    font-size: 50px;
    font-family: 'Bauhaus Std', sans-serif;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.success-message.hidden {
    opacity: 0;
}
@media screen and (max-width: 1440px) {
    .popup-content{
        width: 90% !important;
    } 
    .input-with-list{
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 768px) {
    .popup-content{
        width: 100% !important;
        height: 100vh !important;
        justify-content: flex-start !important;
    }
    .popup-content .creation-content{
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: center;
    }
    .left-creation {
        justify-content: center;
        align-items: center;
    }
    .popup-title{
        font-size: 10px;
        align-items: center;
    }
    .popup-title h1{
        margin: 0;
    }
    .right-creation{
        width: 80% !important;
    }

    .popup .image-upload-placeholder{
        height: 200px !important;
        
    }
    .picker.ofpopup .chosen .ajouter-btn{
        width: fit-content !important;
    }
    .input-with-list{
        flex-direction: column;
    }
    .picker-container{
        
        flex-wrap: wrap;
    }
}
.popup .popup-content.otp .popup-title{
    width: 100%;
}
.buttons{
    display: flex;
    justify-content: space-around;
    width: 50%;
    gap: 50px;
}

.buttons button.oui{
    background-color: white;
    border-color: var(--main-color);
}
button.oui p {
    color: var(--main-color);
}
.buttons button.non p{
    color: white;
}

button.non:hover p{
    color: var(--main-color);
}