:root {
    --main-color: #8dc240;
    --lower-main-color: #8ec2407a;
    --secondary-color: #053162;
    --pro-green: #2b5719;
    --primary: #6a59ff;
    --white: hsl(0, 0%, 100%);
    --gray-text: #6f788b;;
    --border :#dde3ea;
  }
.preorder-text{
    color: red;
    font-weight: 500;
    text-align: center;
}
  .filter-container .page-title{
    color: var(--secondary-color);
    font-size: 30px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    text-align: center;
    font-family: 'Poppins',sans-serif;
}
.filter-container h1 {
    color: var(--secondary-color);
}
.filter-container h1 span{
    background: var(--main-color);
    margin: 5px;
    padding: 5px;
    border-radius: 3px;
    transform: rotate(-5deg);
}
.filter-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    gap: 100px;
    padding-top: 100px;
    height: fit-content;
}

.filter-container .main-content{
    width: 70%;
    height: 100%;
    display: flex;
    gap: 30px;
    font-family: 'Poppins', sans-serif;
}
.filter-container .main-content .right-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    font-family: 'Poppins', sans-serif;

}

.filter-container .main-content .left-content{
    width: 50%;
    height: fit-content;
}

.filter-container .service-rech input{
    outline: none;
    border: none;
    font-size: 18px;
    width: 100%;
}
.filter-container .service-rech{
    width: fit-content;
    height: fit-content;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    border: none;
    width: 100%;
    box-shadow: 0px 5px 50px -20px #35333363 ;

}
.filter-container .left-content{
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 5px 40px -20px #35333363;
    
}
.filter-container .first{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 20px;
}
.filter-container .first p{
    color: var(--secondary-color);
    font-size: 15px;
}
.filter-container .first .first-child{
    font-weight: bold;
    font-size: 17px;
}
.SearchIcon{
    height: fit-content;
}
.filter-container .second{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
}
.first .picker h2{
    font-size: 15px;
    font-weight: 500;
    padding: 0px 0px;
}
.first .picker .chosen{
    width: fit-content;
    padding: 10px 0;
}
.first .picker .custom-select ul{
    width: 310px;
}
.first .picker .chosen .ajouter-btn.address{
    width: fit-content;
}
.first .picker input{
    padding: 20px;
    outline: none;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 5px 50px -20px #35333363 ;
    width: 310px;
}
.second .picker input::placeholder{
    font-size: 15px;
}
.first .chosen-service p{
    color: white;
}
/*PRODUCT*/
.filter-container .main-content .right-content.product{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.product-card{
    width: 250px;
    border-radius: 20px;
    height: 370px;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-card .links{
    cursor: pointer;

}
.product-card img{
    object-fit: contain;
    width: 100%;
    height: 200px;
}
.product-card .title{
    font-weight: 500;
    color: var(--secondary-color);
    font-size: 19px;
}
.product-card .price {
    color: var(--secondary-color);
    font-size: 13px;
}
.product-card .price span{
    color: var(--main-color);
    font-size: 18px;
    font-weight: 600;
}
.product-card button{
    background-color: var(--main-color);
    border: 2px solid transparent;
    color: white;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease;
    font-weight: 500;
    margin-top: 10px;
    width: 80%;
}
.product-card button:hover{
    background-color: #6f9931;
}
.product-card button.ajouté{
    background-color: white;
    color: var(--main-color);
    border: 2px solid var(--main-color);
}
.shopping-cart svg{
    width: 50px;
    height: 100%;
}
.filter-container .second .product-filter-line {
    border-bottom: 1px solid var(--border);
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.filter-container .second .product-filter-line h6{
    font-size: 17px;
    color: var(--secondary-color);
}
.checkboxes{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.checkboxes div{
    display: flex;
    gap: 20px;
    font-size: 15px;
    color: var(--secondary-color);

}
.checkboxes div input{
    border: var(--secondary-color);
}

label.selected{
    color: var(--main-color);
}
.sorting{
    display: flex;
    flex-direction: column;
}
.sorting .dropdown-header{
    cursor: pointer;
    padding: 5px 10px;
    width: 200px;
    color: var(--secondary-color);
    border-radius:5px;
    font-weight: 500;
    outline: none;
    background: var(--main-color);
    color: white;
}

.dropdown-header span{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.dropdown-header span svg path{
    stroke: white;
}
.sorting{
    border:2px solid var(--main-color);
    border-radius: 10px;
    width: fit-content;
}
 .dropdown-options .option{
    padding: 5px;
    cursor: pointer;
}
.dropdown-options .option:hover{
    background-color: #8ec2407a;
}

.pagination{
    display: flex;
    gap: 10px;
}
.pagination button{
    background: white;
    border: 1px solid var(--main-color);
    border-radius: 3px;
    font-size: 15px;
    font-family: 'Poppins',sans-serif;
    padding: 5px 15px;
    cursor: pointer;
    color: var(--secondary-color);
}
button.page{
    background: var(--main-color);
    color: white;
}

button:hover{
    background: #8ec2407a;
}
.filter-btn{
    display: none;
}
@media screen and (max-width: 768px) {
    .main-content {
        flex-direction: column;
        width: 80% !important;
        position: relative;
    }
    .main-content .left-content{
        width: 100% !important;
    }
    .filter-container .page-title{
        font-size: 20px
    }
    .first .picker .custom-select ul{
        width: 270px !important;
    }
    .first .picker input{
        width: 270px !important;
    }
    .left-content{
        position: relative;
    }
    .filter-btn{
        display: block;
        position: absolute;
        top: -50px;
        padding: 10px 20px;
        background-color: var(--main-color);
        border: none;
        border-radius: 5px;
        color: white;
        font-family: 'Poppins' , sans-serif;
        font-weight: 500;
    }
    .filter-btn:hover{
        background-color: #6f9931;
    }
    .filter-container .main-content .right-content.product{
        grid-template-columns: repeat(1,1fr);
        justify-content: center;
    }
    .product-card img{
        object-fit: contain;
        width: 100%;
        height: 200px;
    }
}